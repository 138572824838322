var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"beneficiary-moral"},[_c('span',{staticClass:"moral-benef-title"},[_vm._v(_vm._s(_vm.isEdit ? "Modifier" : "Ajouter")+" un bénéficiaire personne morale")]),_c('div',{staticClass:"giga-fields benef-form"},[(!_vm.beneficiaryMoral)?_c('div',{staticClass:"giga-input_field col-lg-12"},[_c('label',{staticClass:"no-siren-style"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.noSiren),expression:"noSiren"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.noSiren)?_vm._i(_vm.noSiren,null)>-1:(_vm.noSiren)},on:{"change":[function($event){var $$a=_vm.noSiren,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.noSiren=$$a.concat([$$v]))}else{$$i>-1&&(_vm.noSiren=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.noSiren=$$c}},_vm.handleNoSirenChange]}}),_vm._v(" Pas de SIREN")])]):_vm._e(),_c('div',{staticClass:"giga-input_field col-lg-6",class:_vm.beneficiary.siren.$invalid ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v("SIREN")]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.siren.$model),expression:"beneficiary.siren.$model"}],staticClass:"small-input",class:_vm.beneficiary.siren.$anyDirty && _vm.beneficiary.siren.$invalid
              ? 'field-hasError'
              : '',attrs:{"type":"text","id":"siren","disabled":_vm.isVIEWER || _vm.noSiren},domProps:{"value":(_vm.beneficiary.siren.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.beneficiary.siren, "$model", $event.target.value)},_vm.debouncedOnSirenChange]}}),(
            (_vm.beneficiary.siren.$anyDirty && _vm.beneficiary.siren.$invalid) ||
              _vm.sirenNotFound
          )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.siren.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e(),(!_vm.beneficiary.siren.pattern)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("siren_invalid"))+" ")]):_vm._e(),(_vm.sirenNotFound)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("siret_not_found_in_db"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-6",class:_vm.beneficiary.siret.$invalid ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v("SIRET")]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.siret.$model),expression:"beneficiary.siret.$model"}],staticClass:"small-input",class:_vm.beneficiary.siret.$anyDirty && _vm.beneficiary.siret.$invalid
              ? 'field-hasError'
              : '',attrs:{"id":"siret","disabled":_vm.isVIEWER || _vm.noSiren},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.beneficiary.siret, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.onSiretChange]}},_vm._l((_vm.siretOptions),function(siret){return _c('option',{key:siret,domProps:{"value":siret}},[_vm._v(_vm._s(siret))])}),0),(_vm.beneficiary.siret.$anyDirty && _vm.beneficiary.siret.$invalid)?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.siret.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e(),(!_vm.beneficiary.siret.pattern)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("siret_invalid_benef"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-12",class:!_vm.beneficiary.addressName.required ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("address")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.addressName.$model),expression:"beneficiary.addressName.$model"}],staticClass:"small-input",class:_vm.beneficiary.addressName.$anyDirty &&
            _vm.beneficiary.addressName.$invalid
              ? 'field-hasError'
              : '',attrs:{"type":"text","id":"addressName","disabled":_vm.isVIEWER},domProps:{"value":(_vm.beneficiary.addressName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.beneficiary.addressName, "$model", $event.target.value)}}}),(
            _vm.beneficiary.addressName.$anyDirty &&
              _vm.beneficiary.addressName.$invalid
          )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.addressName.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-3",class:!_vm.beneficiary.raisonSociale.required ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("company_name")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.raisonSociale.$model),expression:"beneficiary.raisonSociale.$model"}],staticClass:"small-input",class:_vm.beneficiary.raisonSociale.$anyDirty &&
            _vm.beneficiary.raisonSociale.$invalid
              ? 'field-hasError'
              : '',attrs:{"type":"text","id":"raisonSociale","disabled":_vm.isVIEWER},domProps:{"value":(_vm.beneficiary.raisonSociale.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.beneficiary.raisonSociale, "$model", $event.target.value)}}}),(
            _vm.beneficiary.raisonSociale.$anyDirty &&
              _vm.beneficiary.raisonSociale.$invalid
          )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.raisonSociale.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-3",class:!_vm.beneficiary.type.required ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("beneficiary_type")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.type.$model),expression:"beneficiary.type.$model"}],staticClass:"small-input",class:_vm.beneficiary.type.$anyDirty && _vm.beneficiary.type.$invalid
              ? 'field-hasError'
              : '',attrs:{"id":"type","disabled":_vm.isVIEWER},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.beneficiary.type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.beneficiaryTypes),function(label,key){return _c('option',{key:key,domProps:{"value":key}},[_vm._v(_vm._s(label))])}),0),(_vm.beneficiary.type.$anyDirty && _vm.beneficiary.type.$invalid)?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.type.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-3",class:!_vm.beneficiary.codePostal.required ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("postal_code")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.codePostal.$model),expression:"beneficiary.codePostal.$model"}],staticClass:"small-input",class:_vm.beneficiary.codePostal.$anyDirty &&
            _vm.beneficiary.codePostal.$invalid
              ? 'field-hasError'
              : '',attrs:{"type":"text","id":"codePostal","disabled":_vm.isVIEWER},domProps:{"value":(_vm.beneficiary.codePostal.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.beneficiary.codePostal, "$model", $event.target.value)},_vm.getCitiesByZipCode]}}),(
            _vm.beneficiary.codePostal.$anyDirty &&
              _vm.beneficiary.codePostal.$invalid
          )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.codePostal.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-3",class:!_vm.beneficiary.nomCommune.required ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("city")))]),_c('div',{staticClass:"input-field_area"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.nomCommune.$model),expression:"beneficiary.nomCommune.$model"}],staticClass:"small-input",class:_vm.beneficiary.nomCommune.$anyDirty &&
            _vm.beneficiary.nomCommune.$invalid
              ? 'field-hasError'
              : '',attrs:{"id":"nomCommune","disabled":_vm.isVIEWER},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.beneficiary.nomCommune, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.cities),function(city){return _c('option',{key:city.id,domProps:{"value":city.nomCommune}},[_vm._v(" "+_vm._s(city.nomCommune)+" ")])}),0),(
            _vm.beneficiary.nomCommune.$anyDirty &&
              _vm.beneficiary.nomCommune.$invalid
          )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.nomCommune.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-6",class:!_vm.beneficiary.personneMoralTelephone.required ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("phone")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.personneMoralTelephone.$model),expression:"beneficiary.personneMoralTelephone.$model"}],staticClass:"small-input",class:_vm.beneficiary.personneMoralTelephone.$anyDirty &&
            _vm.beneficiary.personneMoralTelephone.$invalid
              ? 'field-hasError'
              : '',attrs:{"type":"tel","id":"personneMoralTelephone","disabled":_vm.isVIEWER},domProps:{"value":(_vm.beneficiary.personneMoralTelephone.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.beneficiary.personneMoralTelephone, "$model", $event.target.value)}}}),(
            _vm.beneficiary.personneMoralTelephone.$anyDirty &&
              _vm.beneficiary.personneMoralTelephone.$invalid
          )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.personneMoralTelephone.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-6"}),_c('div',{staticClass:"giga-input_field col-lg-6",class:!_vm.beneficiary.personneMoralEmail.required ? 'field-isRequired' : ''},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("email")))]),_c('div',{staticClass:"input-field_area"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.beneficiary.personneMoralEmail.$model),expression:"beneficiary.personneMoralEmail.$model"}],staticClass:"small-input",class:_vm.beneficiary.personneMoralEmail.$anyDirty &&
            _vm.beneficiary.personneMoralEmail.$invalid
              ? 'field-hasError'
              : '',attrs:{"type":"text","id":"phone","disabled":_vm.isVIEWER},domProps:{"value":(_vm.beneficiary.personneMoralEmail.$model)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.beneficiary.personneMoralEmail, "$model", $event.target.value)},_vm.debouncedCheckValidityEmail]}}),(
            _vm.beneficiary.personneMoralEmail.$anyDirty &&
              _vm.beneficiary.personneMoralEmail.$invalid
          )?_c('div',{staticClass:"validation-field-holder"},[(!_vm.beneficiary.personneMoralEmail.required)?_c('small',[_vm._v(" "+_vm._s(_vm.$t("required_field"))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"giga-input_field col-lg-6"},[_c('label',{staticClass:"input-field_label"},[_vm._v(_vm._s(_vm.$t("status")))]),_c('div',{staticClass:"input-field_area"},[(_vm.emailStatus)?_c('div',[(_vm.emailStatus === 'loading')?_c('div',[_vm._m(0)]):(_vm.emailStatus === 'Active')?_c('div',[_vm._m(1)]):(_vm.emailStatus === 'Unspecified')?_c('div',[_vm._m(2)]):(_vm.emailStatus === 'Invalid_Syntax')?_c('div',[_vm._m(3)]):(_vm.emailStatus === 'Inactive')?_c('div',[_vm._m(4)]):_vm._e()]):_vm._e()])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-status"},[_c('img',{staticClass:"icon-status",attrs:{"src":require("@/assets/images/spinner.svg"),"alt":""}}),_c('span',{staticClass:"label-status"},[_vm._v("Vérification en cours")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-status"},[_c('img',{staticClass:"custom-icon",attrs:{"src":require("@/assets/images/check-list.svg"),"alt":""}}),_c('span',{staticClass:"label-status"},[_vm._v("Actif")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-status"},[_c('img',{staticClass:"custom-icon",attrs:{"src":require("@/assets/images/alert-notif.svg"),"alt":""}}),_c('span',{staticClass:"label-status"},[_vm._v("Non spécifié")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-status"},[_c('img',{staticClass:"custom-icon",attrs:{"src":require("@/assets/images/alert-notif.svg"),"alt":""}}),_c('span',{staticClass:"label-status"},[_vm._v("Syntaxe invalide")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"email-status"},[_c('img',{staticClass:"custom-icon",attrs:{"src":require("@/assets/images/alert-notif.svg"),"alt":""}}),_c('span',{staticClass:"label-status"},[_vm._v("Inactif")])])}]

export { render, staticRenderFns }